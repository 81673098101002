<template>
  <div class="mini-line-chart-container">
    <div :id="prefixedChartId" class="mini-line-chart" data-pw="chart"></div>
    <div class="chart-header-small">
      <h3>{{ label }} ({{ $t('chf_ew') }})</h3>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import * as d3 from 'd3'
import { colorBlue } from '@/plugins/colors'
import { MINI_LINE_MIN_VALUE, MINI_LINE_MAX_VALUE } from '@/plugins/constants'
import { formatNumber } from '@/plugins/format'
import { compose, parseNumber, translateString } from '~/plugins/utils'
import { prop } from '~/plugins/accessors'
import { parseYear } from '~/plugins/parse'
let sszvis

if (process.client) {
  sszvis = require('sszvis')
}

export default {
  props: {
    chartId: { type: String, default: '' },
    chartData: { type: Array, default: () => [] },
    label: { type: String, default: '' },
    dateColumn: { type: String, default: 'Jahr' },
    valueColumn: { type: String, default: 'Wert' },
  },
  data() {
    return {
      config: {
        dateColumn: this.dateColumn,
        valueColumn: this.valueColumn,
        rulerLabel: '',
        xLabel: '',
        yLabel: '',
        lineColor: colorBlue,
      },
      state: {
        data: [],
        years: [0, 0],
        lineData: [],
        selection: [],
        currentDate: null,
      },
    }
  },
  computed: {
    minY() {
      return MINI_LINE_MIN_VALUE
    },
    maxY() {
      return MINI_LINE_MAX_VALUE
    },
    bounds() {
      return {
        width: 280,
        height: 90,
        top: 14,// 16,
        bottom: 25,
        left: 3,
        right: 80,
      }
    },
    title() {
      return this.chartId
    },
    prefixedChartId() {
      return this.chartId
    },
    hashedChartId() {
      return '#' + this.prefixedChartId
    },
    xAcc() {
      return prop('date')
    },
    yAcc() {
      return prop('value')
    },
  },
  mounted() {
    const parsedData = this.chartData.map((d) => {
      return {
        date: parseYear(d[this.config.dateColumn]),
        value: parseNumber(d[this.config.valueColumn]),
      }
    })
    this.prepareState(parsedData)
  },
  beforeDestroy() {
    sszvis.viewport.off('resize', this.resize)
  },
  methods: {
    prepareState(data) {
      this.state.data = data
      this.state.years = d3.extent(this.state.data, this.xAcc)
      this.state.lineData = [this.state.data]

      this.resetDate()
    },
    changeDate(inputDate) {
      const date = this.closestDatum(this.state.data, prop('date'), inputDate)
        .date
      if (this.state.currentDate === date) return

      this.state.currentDate = date
      this.state.selection = this.state.data.filter((d) => {
        return d.date === this.state.currentDate && !isNaN(d.value)
      })

      this.render(this.state)
    },
    resetDate() {
      const mostRecentDate = d3.max(this.state.data, prop('date'))
      this.changeDate(mostRecentDate)
    },
    resize() {
      this.render(this.state)
    },
    closestDatum(data, accessor, datum) {
      const i = d3.bisector(accessor).left(data, datum, 1)
      const d0 = data[i - 1]
      const d1 = data[i] || d0
      return datum - accessor(d0) > accessor(d1) - datum ? d1 : d0
    },
    isSelected(d) {
      return sszvis.contains(
        this.state.selection.map(this.xAcc).map(String),
        String(d)
      )
    },
    isStartOfYear(d) {
      return d.getMonth() === 0
    },
    render(state) {
      const bounds = sszvis.bounds(this.bounds)

      // Scales
      const xScale = d3
        .scaleTime()
        .domain(this.state.years)
        .range([0, bounds.innerWidth])

      // take minY and maxY from constants in order to sycnhronise mini line charts on the site
      const minY = this.minY
      const maxY = this.maxY
      // Add a bit of additional bottom padding to prevent the y-axis value from
      // touching the x-axis if we are having negative y values
      const bottomPadding = minY < 0 ? 15 : 0

      const yScale = d3
        .scaleLinear()
        .domain([minY, maxY])
        .range([bounds.innerHeight, 0])

      // Layers
      const chartLayer = sszvis.createSvgLayer(this.hashedChartId, bounds, {
        title: this.config.title,
        description: this.config.description,
      })

      // Components
      const line = sszvis
        .line()
        .x(compose(xScale, this.xAcc))
        .y(compose(yScale, this.yAcc))
        .stroke(this.config.lineColor)
        .strokeWidth(3)

      let xTickValues = xScale.ticks(d3.timeYear, 1)

      // we only want the first and last tick
      xTickValues = [_.first(xTickValues), _.last(xTickValues)]

      // add the selected year as tick
      xTickValues = xTickValues.concat(state.selection.map(this.xAcc))

      // remove duplicates
      xTickValues = _.uniqBy(xTickValues, (d) => {
        return d.getTime()
      })

      const xAxis = sszvis.axisX
        .time()
        .scale(xScale)
        .orient('bottom')
        .tickValues(xTickValues)
        .highlightTick(this.isSelected)
        .title(this.config.xLabel)

      const yAxis = sszvis
        .axisY()
        .scale(yScale)
        .orient('right')
        .title(this.config.yLabel)
        .dyTitle(-8)
        .contour(true)
        .showZeroY(minY < 0)
        .ticks(0)

      const ruler = sszvis
        .annotationRuler()
        .top(0)
        .bottom(bounds.innerHeight + bottomPadding)
        .x(compose(xScale, this.xAcc))
        .y(compose(yScale, this.yAcc))
        .label((d) => {
          return formatNumber(d.value) + ' ' + this.config.rulerLabel + ''
        })

        .color(this.config.lineColor)

      // Rendering
      const lineChart = chartLayer
        .selectGroup('lineChart')
        .datum(state.lineData)

      lineChart.selectGroup('line').call(line)

      lineChart
        .selectGroup('xAxis')
        .attr(
          'transform',
          translateString(0, bounds.innerHeight + bottomPadding)
        )
        .call(xAxis)

      lineChart.selectGroup('yAxis').call(yAxis)

      chartLayer.selectGroup('ruler').datum(state.selection).call(ruler)

      // Interaction
      const interactionLayer = sszvis
        .move()
        .xScale(xScale)
        .yScale(yScale)
        .on('move', this.changeDate)
        .on('end', this.resetDate)

      chartLayer.selectGroup('interaction').call(interactionLayer)

      sszvis.viewport.on('resize', this.resize)

      // select this.hashedChartId using d3 and set style border to red
      // d3.select(this.hashedChartId).select('svg').style('border', '1px solid gray')
    },
  },
}
</script>

<style scoped></style>
